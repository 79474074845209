import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AboutHoverText from "../Components/AboutHoverText/AboutHoverText";

export default function ProvideMoreSection() {
  return (
    <>
      <Container
        fluid
        className="text-white bg-lightblue"
        style={{ transform: "translate(0,-70px)", zIndex: "1" }}
      >
        <Row className="py-5"></Row>
        <Row className="py-4"></Row>
        <Row>
          <Col className="mx-auto pt-3 pb-5" lg={7}>
            <h1 className="font-900">WE PROVIDE EVEN MORE!</h1>
          </Col>
        </Row>
        <Row className="py-5"></Row>
        <Row className="py-4"></Row>
      </Container>
      <Container
        fluid
        className="bg-white py-5"
        style={{
          transform: "translate(0,-70px)",
          zIndex: "4",
          position: "relative",
        }}
      >
        <Row>
          <Col lg={8} xs={12} className="mx-auto">
            <Row>
              <Col lg={3} xs={10} className="mx-auto bg-light text-lightblue font-900 m-3 p-0 morebox shadowready">
                <AboutHoverText
                  title={"Bandwidth and unlimited traffic"}
                  text={
                    "Each server has a minimum public bandwidth of 1 Gbit/s, with a burst available to absorb occasional peak traffic. Incoming and outgoing traffic is unlimited and free of charge."
                  }
                ></AboutHoverText>
              </Col>
              <Col lg={3} xs={10} className="mx-auto bg-light text-lightblue font-900 m-3 p-0 morebox shadowready">
                <AboutHoverText
                  title={"Anti-DDoS protection"}
                  text={
                    "One of the most crucial feature that helps guarantee maximum security for your infrastructures, an anti-DDoS system is included for all our servers. This protects your servers and trading infrastructure from all potential DDoS attacks intended to disrupt your trading infrastructure."
                  }
                ></AboutHoverText>
              </Col>
              <Col lg={3} xs={10} className="mx-auto bg-light text-lightblue font-900 m-3 p-0 morebox shadowready">
                <AboutHoverText
                  title={"Support"}
                  text={
                    "When you opt for our hosting solution, you can rest assured that you get instant technical support at any time during the day."
                  }
                ></AboutHoverText>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
