import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function SolutionsSection() {
  return (
    <Container fluid style={{ backgroundColor: "#F5FCFF" }}>
      <Row style={{ paddingTop: "200px" }}></Row>
      <Row>
        <Col className="mx-auto" lg={5}>
          <h1 className="text-lightblue font-900">
            The BridgeRock Temple Solutions
          </h1>
          <p
            className="text-lightblue my-4"
            style={{ fontSize: 21, lineHeight: "1.3" }}
          >
            We are proud to offer our clients a comprehensive suite of services
            to ensure the smooth running of their trading platforms. Our team of
            experts has years of experience working with different platforms,
            and we can guarantee top-notch service and support to help you
            manage your trading operations.
          </p>
        </Col>
      </Row>
      <Row className="text-lightblue text-start py-5">
        <Col xl={6} lg={10} md={8} className="mx-auto">
          <Row>
            <Col xl={6} md={10} className="my-5 my-lg-2">
              <h1 className="font-900 fst-italic" style={{ lineHeight: 1.5 }}>
                Trading Platform Administration and Maintenance
              </h1>
              <p className="my-4" style={{ fontSize: 14, lineHeight: 1.2 }}>
                We provide a variety of services pertaining to trading platforms
                encompassing setup, configuration, monitoring and maintenance.
                We have an extensive experience working on popular front-end
                platforms notably MT4, MT5 and cTrader.
              </p>
              <button
                className="btn text-white py-3 rounded-4 bg-lightblue mt-5"
                style={{ paddingLeft: "70px", paddingRight: "70px" }}
              >
                Learn More
              </button>
            </Col>
            <Col xl={6} md={10} style={{ display: "flex", alignItems: "end" }}>
              <img
                alt="graph"
                style={{ width: "80%" }}
                src={require("../Images/guywithgraph.png")}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bgcurve text-start py-5">
        <Col xl={7} lg={10} md={8} className="mx-auto">
          <Row>
            <Col xl={6} md={10} style={{ display: "flex", alignItems: "end" }}>
              <img
                alt="graph"
                style={{ width: "80%" }}
                src={require("../Images/globe.png")}
              />
            </Col>
            <Col xl={6} md={10} className="my-5 my-lg-2">
              <h1 className="font-900 fst-italic" style={{ lineHeight: 1.5 }}>
                Bridge
              </h1>

              <h1 className="font-900 fst-italic" style={{ lineHeight: 1.5 }}>
                Administration
              </h1>

              <p className="my-4" style={{ fontSize: 14, lineHeight: 1.5 }}>
                To complement your platform administration, our experienced
                staff are able to handle and manage all aspects of your bridges
                irrespective of the vendor. We have dealt with complex setups
                working with different technology providers such as PrimeXM,
                oneZero, Integral, FXCubic, Centroid and many other vendors
                offering FIX Engines.
              </p>
              <button
                className="btn text-lightblue py-3 rounded-4 bg-white mt-5 font-700"
                style={{ paddingLeft: "70px", paddingRight: "70px" }}
              >
                Learn More
              </button>
            </Col>
          </Row>
          <Row className="my-5"></Row>
          <Row className="my-5"></Row>
        </Col>
      </Row>
      <Row className="text-lightblue text-start py-5">
        <Col xl={6} lg={10} md={8} className="mx-auto">
          <Row>
            <Col xl={6} md={10} className="my-5 my-lg-2">
              <h1 className="font-900 fst-italic" style={{ lineHeight: 1.5 }}>
                Hosting Solutions and Server Management
              </h1>
              <p className="my-4" style={{ fontSize: 14, lineHeight: 1.2 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <button
                className="btn text-white py-3 rounded-4 bg-lightblue mt-5"
                style={{ paddingLeft: "70px", paddingRight: "70px" }}
              >
                Learn More
              </button>
            </Col>
            <Col xl={6} md={10} style={{ display: "flex", alignItems: "end" }}>
              <img
                alt="graph"
                style={{ width: "100%" }}
                src={require("../Images/cloud.png")}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
