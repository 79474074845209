import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function GetStartedSection() {
  return (
    <>
      <Container fluid>
        <Row className="py-5" style={{ backgroundColor: "#F5FCFF" }}></Row>
      </Container>
      <Container
        fluid
        className="bglines pb-5"
        style={{ minHeight: "50vh", backgroundColor: "#F5FCFF" }}
      >
        <Row>
          <Col
            xl={6}
            lg={8}
            md={10}
            xs={10}
            className="mx-auto bg-light shadowready"
            style={{
              padding: "70px",
              borderRadius: "23px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Row
              className="m-0 p-0"
              style={{
                width: "100%",
              }}
            >
              <Col
                lg={6}
                md={12}
                className="m-0 p-0"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <h2
                  className=" text-lightblue font-700"
                  style={{ lineHeight: 2 }}
                >
                  Ready to get started?
                </h2>
              </Col>
              <Col lg={6} md={12} className="m-0 p-0">
                <button
                  className="btn text-white px-4 px-lg-5 py-3 rounded-4 bg-lightblue font-600"
                  style={{}}
                >
                  Contact Us
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
