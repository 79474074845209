import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function AboutSection() {
  return (
    <Container
    id={"about"}
      fluid
      className="text-white bg-lightblue pt-5"
      style={{
        transform: "translate(0,-70px)",
        zIndex: "1",
      }}
    >
      <Row className="pt-5">
        <span
          className="font-900 text-lightblue py-4 fst-italic"
          style={{ fontSize: 36, backgroundColor: "#F5FCFF" }}
        >
          ABOUT US
        </span>
      </Row>
      <Row className="my-4"></Row>
      <Row className="pt-5">
        <Col lg={7} className="mx-auto">
          <p className="font-800" style={{ fontSize: 32 }}>
            THE BRIDGEROCK TEMPLE LTD,
          </p>
          <p style={{ fontSize: 32 }}>
            a company incorporated in Cyprus with Reg. No. HE 438606, and
            registered office at 365 Agiou Andreou Street, Efstathiou Court,
            Office 201, 2nd Floor, 3035 Limassol, Cyprus
          </p>
          <p className="font-800" style={{ fontSize: 32 }}>
            VAT 10438606S
          </p>
        </Col>
      </Row>
      <Row
        className="text-white bg-lightblue py-5"
        style={{ transform: "translate(0,70px)", zIndex: "1" }}
      ></Row>
    </Container>
  );
}
