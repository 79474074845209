import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./MainSection.css";

export default function MainSection() {
  return (
    <Container
      fluid
      className="text-center bgGradientMain"
      style={{ minHeight: "100vh" }}
    >
      <Row style={{ paddingTop: "100px" }}></Row>
      <Row style={{ transform: "translate(0px,150px)" }}>
        <Col lg={7} className="mx-auto">
          <div
            className="bg-white text-blue pb-3 pt-4 font-800"
            style={{
              fontSize: "24px",
              display: "inline-block",
              paddingLeft: "100px",
              paddingRight: "100px",
              maxWidth:"90%",
              borderStartStartRadius: "34px",
              borderStartEndRadius: "34px",
            }}
          >
            GET STARTED
          </div>
          <img
            alt="banner"
            src={require("../Images/mainbanner.png")}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </Container>
  );
}
