import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function TradingplatformSection() {
  let hostingsolutions = [
    {
      icon: require("../Images/icon1.png"),
      text: "We partner with providers across different locations to ensure the provision of servers across different Datacenters around the world to cater brokers with client base in different geographical locations for a seamless trading experience.",
    },
    {
      icon: require("../Images/icon2.png"),
      text: "We over a variety of packages to suit brokers of different sizes from simple to a very complex infrastructure.",
    },
    {
      icon: require("../Images/icon3.png"),
      text: "Dedicated servers from give your businesses an unmatched level of security and control with the latest hardware and top performance with our updated dedicated server range.",
    },
    {
      icon: require("../Images/icon4.png"),
      text: "Our servers are perfect for resource-intensive applications that need guaranteed hardware resources. Use your server without any restrictions, backed-up by our trusted infrastructure and 24/7 support team.",
    },
    {
      icon: require("../Images/icon5.png"),
      text: "We manage, monitor and upgrade your severs on regular basis and ensure any faulty components are replaced swiftly.",
    },
    {
      icon: require("../Images/icon6.png"),
      text: "By opting for our hosting solution, all resources are fully allocated to your server to ensure best performance at all time. Deploying a server takes nearly 24 hours in any of the selected datacenter around the globe. Brokers can benefit from a secure, high-resilience network to ensure business continuity at all time.",
    },
    {
      icon: require("../Images/icon7.png"),
      text: "Your server provision is coupled with many important features that ensure highest level of security and availability.",
    },
  ];
  return (
    <Container
    id={"services"}
      fluid
      className="text-lightblue"
      style={{ backgroundColor: "#F5FCFF" }}
    >
      <Row>
        <Col className="mx-auto" lg={7}>
          <h2 className="text-lightblue font-900">
            Trading Platform Administration and Maintenance
          </h2>
        </Col>
      </Row>
      <Row>
        <Col xl={7} lg={8} md={8} xs={10} className="mx-auto text-start py-4">
          <Row>
            <Col xl={4} lg={7} md={7} xs={12} className="mx-auto">
              <div
                className="bg-white shadow p-5 font-600 my-3 text-lightblue"
                style={{
                  fontSize: 14,
                  lineHeight: 2,
                  borderRadius: 25,
                  minHeight: 360,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ul className="p-0 m-0">
                  <li>Setup, configuration and resources monitoring</li>
                  <li>
                    Setup and configuration of different components including
                    Groups, Symbols, Managers, etc
                  </li>
                  <li>
                    Setup of trading profiles with various spreads, commissions,
                    swaps and rollover updates
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={4} lg={7} md={7} xs={12} className="mx-auto">
              <div
                className="bg-lightblue shadow p-5 my-3 font-600 text-white"
                style={{
                  fontSize: 14,
                  lineHeight: 2,
                  borderRadius: 25,
                  minHeight: 360,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ul className="p-0 m-0">
                  <li>Setup of Datafeeds and Gateways via different sources</li>
                  <li>Setup of bridges and connection into different LPs</li>
                  <li>
                    Correction of charts and quotes and restoring deleted and
                    archived accounts and order
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={4} lg={7} md={7} xs={12} className="mx-auto">
              <div
                className="bg-white shadow p-5 font-600 my-3 text-lightblue"
                style={{
                  fontSize: 14,
                  lineHeight: 2,
                  borderRadius: 25,
                  minHeight: 360,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ul className="p-0 m-0">
                  <li>Data migration between different servers</li>
                  <li>Switch to backup in the event of main server failure</li>
                  <li>
                    Installation and configuration of third-party plugins and
                    application
                  </li>
                  <li>24/5 support and monitoring</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="mx-auto" lg={7}>
          <h2 className="text-lightblue font-900">Bridge Administration</h2>
        </Col>
      </Row>
      <Row>
        <Col xl={7} lg={8} md={8} xs={10} className="mx-auto text-start py-4">
          <Row>
            <Col xl={4} lg={7} md={7} xs={12} className="mx-auto">
              <div
                className="bg-lightblue shadow p-5 font-600 my-3 text-white"
                style={{
                  fontSize: 14,
                  lineHeight: 2,
                  borderRadius: 25,
                  minHeight: 400,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ul className="p-0 m-0">
                  <li>
                    Setup of all traded instrument on the bridge in addition to
                    mapping into Taker platforms and LPs
                  </li>
                  <li>
                    Creation of different Liquidity Pools from one or multiple
                    LPs to be used as standalone or aggregated pool
                  </li>
                  <li>
                    Creation of tailored price streams with custom markups and
                    liquidity sources
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={4} lg={7} md={7} xs={12} className="mx-auto">
              <div
                className="bg-white shadow p-5 my-3 font-600 text-lightblue"
                style={{
                  fontSize: 14,
                  lineHeight: 2,
                  borderRadius: 25,
                  minHeight: 400,

                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ul className="p-0 m-0">
                  <li>Routing of different flows into selected venues</li>
                  <li>Setup of different STP and B-Book execution profile</li>
                  <li>
                    Setup of FIX APIs with selected Liquidity sources and
                    tailored prices
                  </li>
                  <li>
                    Setup of MT4/MT5 coverage accounts to capture orders routed
                    to LP or coming via FIX API or WL clients
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={4} lg={7} md={7} xs={12} className="mx-auto">
              <div
                className="bg-lightblue shadow p-5 font-600 my-3 text-white"
                style={{
                  fontSize: 14,
                  lineHeight: 2,
                  borderRadius: 25,
                  minHeight: 400,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ul className="p-0 m-0">
                  <li>
                    Troubleshooting all incidents related to pricing and
                    execution through a variety of system and FIX logs
                  </li>
                  <li>Assistance with reconciliation across multiple LPs</li>
                  <li>
                    Providing a variety of reports for different trading and
                    calculation activities
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="mx-auto pt-3 pb-5" lg={7}>
          <h2 className="text-lightblue font-900">
            Hosting Solutions and Server Management
          </h2>
        </Col>
      </Row>
      <Row>
        <Col className="mx-auto" lg={6} xs={11}>
          {hostingsolutions.map((x) => (
            <Row
              className="bg-white shadowready my-3"
              style={{ borderRadius: 16, zIndex: 4, position: "relative" }}
            >
              <Col lg={1}></Col>
              <Col
                lg={3}
                className="p-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  className="m-4"
                  style={{ width: "50px" }}
                  src={x.icon}
                  alt="1"
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  className=" p-3 m-0 text-start font-800 text-lightblue"
                  style={{ fontSize: 14, lineHeight: 1.3 }}
                >
                  {x.text}
                </p>
              </Col>
              <Col lg={2}></Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
}
