import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Contact.css";
import axios from "axios";

export default function Contact() {
  const [loading, setLoading] = useState(false);

  let handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let data = {
      name: e.target[0].value,
      email: e.target[1].value,
      message: e.target[2].value,
    };

    let text =
      "Name: " +
      data.name +
      "\n" +
      "Email: " +
      data.email +
      "\n" +
      "message: " +
      data.message;

    axios
      .post(
        "https://test1fehost.b4a.app/sendcontactbridge",
        {
          text: text,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((x) => {
        setLoading(false);
        e.target.reset();
        alert("Email Sent!");
        console.log(x);
      });
  };
  return (
    <Container
      id={"contact"}
      fluid
      className="text-white bg-lightblue"
      style={{ transform: "translate(0,-70px)", zIndex: "1" }}
    >
      <Row className="py-5"></Row>
      <Row className="py-4"></Row>
      <Row>
        <Col className="mx-auto pt-3 pb-5" lg={7}>
          <h2 className="font-900">Have a Question ?</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={5} className="mx-auto">
          <form onSubmit={handleSubmit}>
            <Row>
              <div class="input-group mb-3">
                <input
                  disabled={loading}
                  required
                  type="text"
                  class="form-control inputBox text-lightblue"
                  placeholder="Your Name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </Row>
            <Row>
              <div class="input-group mb-3">
                <input
                  disabled={loading}
                  type="email"
                  required
                  class="form-control inputBox text-lightblue"
                  placeholder="Your Email"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </Row>
            <Row>
              <div class="input-group mb-3">
                <textarea
                  disabled={loading}
                  required
                  minLength={20}
                  style={{ height: 300, paddingTop: 25 }}
                  type="text"
                  class="form-control inputBox text-lightblue"
                  placeholder="Your Message"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </Row>
            <Row className="my-4">
              <Col>
                <button
                  disabled={loading}
                  type="submit"
                  class=" py-4 form-control btn btn-light text-lightblue font-900"
                  style={{ width: 300, fontSize: 24, borderRadius: 15 }}
                >
                  {loading ? "Sending..." : "SEND"}
                </button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  );
}
