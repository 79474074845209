import logo from "./logo.svg";
import "./App.css";
import NavBar from "./Components/NavBar";
import MainSection from "./Sections/MainSection";
import SolutionsSection from "./Sections/SolutionsSection";
import GetStartedSection from "./Sections/GetStartedSection";
import TradingplatformSection from "./Sections/TradingplatformSection";
import ProvideMoreSection from "./Sections/ProvideMoreSection";
import Contact from "./Components/ContactForm/Contact";
import Footer from "./Components/Footer";
import AboutSection from "./Sections/AboutSection";

function App() {
  return (
    <>
      <NavBar />
      <MainSection />
      <SolutionsSection />
      <GetStartedSection />
      <TradingplatformSection />
      <ProvideMoreSection />
      <Contact />
      <AboutSection />
    </>
  );
}

export default App;
