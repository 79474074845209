import React from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";

export default function NavBar() {
  return (
    <Container
      className="pt-2"
      style={{ position: "absolute", left: 0, right: 0, top: 0 }}
    >
      <Row>
        <Col className="mx-auto" lg={10}>
          <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
            <Container>
              <Navbar.Brand href="#home">
                <img
                  alt=""
                  src={require("../Images/logo.png")}
                  width="50"
                  height="50"
                  className="d-inline-block align-top"
                />
              </Navbar.Brand>
              <Navbar.Text className="text-center mx-3 text-white font-700">
                <p className="p-0 m-0">THE BRIDGEROCK</p>
                <p className="p-0 m-0">TEMPLE</p>
              </Navbar.Text>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav>
                  <Nav.Link className="mx-3 text-white font-600" href="#services">
                    Services
                  </Nav.Link>
                  <Nav.Link className="mx-3 text-white font-600" href="#about">
                    About us
                  </Nav.Link>
                  <Nav.Link
                    className="mx-3 bg-white text-dark btn rounded-4 px-5 font-800"
                    style={{ fontSize: 17 }}
                    href="#contact"
                  >
                    Contact us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Col>
      </Row>
    </Container>
  );
}
