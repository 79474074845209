import React from "react";
import "./AboutHoverText.css";

export default function AboutHoverText(props) {
  return (
    <div class="aboutboxContainer">
      <div className="text-center aboutCard">
        <div
          class="card-body"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p>{props.title}</p>
        </div>
      </div>
      <div
        class="aboutoverlay flexcolcenter bg-lightblue text-white"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div class="text text-center px-4" style={{ fontSize: 15 }}>
          {props.text}
        </div>
      </div>
    </div>
  );
}
